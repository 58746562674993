<template>
<span>

    <v-form ref="form" autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select v-model="product.product_subcategory_id" :items="loadProductSubcategories" item-text="prod_sc_nome" item-value="prod_sc_id" label="Selecione uma Subcategoria..." outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select v-model="product.product_brand_id" :items="loadProductBrands" item-text="prod_b_nome" item-value="prod_b_id" label="Selecione uma Marca..." outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="product.prod_codigo" label="Códígo do Produto" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="input-v-money">
                        <label for="">Percentual de Desconto ( % )</label>
                        <money v-model="product.prod_perc_desconto" v-bind="money"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn class="mr-3" large color="error" @click="reset">
                        <v-icon class="mdi mdi-cancel mr-2"></v-icon>
                        Limpar Formulário
                    </v-btn>
                    <v-btn large color="success" @click="onSubmit">
                        <v-icon class="mdi mdi-check mr-2"></v-icon>
                        Aplicar Desconto
                    </v-btn>
                </v-col>

            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    Money
} from 'v-money'
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    name: "FormProductDiscountComponent",
    props: {
        product: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_id: '',
                    product_subcategory_id: '',
                    product_brand_id: '',
                    prod_codigo: '',
                    prod_perc_desconto: 0.00

                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        require: true,
    },
    created() {
        this.$store.dispatch('loadProductSubcategories');
        this.$store.dispatch('loadProductBrands');

    },
    computed: {
        loadProductSubcategories() {
            if (this.$store.state.productsubcategories.items.data == null)
                return []

            return this.$store.state.productsubcategories.items.data;
        },
        loadProductBrands() {
            if (this.$store.state.productbrands.items.data == null)
                return []

            return this.$store.state.productbrands.items.data;
        },
        isPercDiscount() {
            if (this.product.prod_perc_desconto != 0.00) {
                return true
            }

            return false
        }
    },
    data() {
        return {
            valid: true,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('updateDiscount', this.product)
                .then((response) => {
                    if (response.data.success) {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: "Sucesso",
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.reset();
                    } else {
                        this.$swal({
                            position: "center",
                            icon: "info",
                            title: "Atenção",
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 6000
                        });
                    }
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    console.log(error)
                    //this.errors = error.response.data.errors
                })

        },
        onSubmitDiscontByBrand() {

        },
        onSubmitDiscontByProduct() {

        },
        reset() {
            this.$refs.form.reset();
            this.product.prod_perc_desconto = 0.00;
        }
    },
    components: {
        Money
    },
};
</script>

<style lang="scss">
h3 {
    margin-bottom: .5rem;
}

.input-v-money {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    border-radius: 4px;
    padding: 6px 12px;
}

.input-v-money>label {
    color: rgba(255, 255, 255, 0.3);
    font-size: .8rem;
}

.input-v-money>input {
    color: #FFF;
}

:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.v-btn--large {
    height: 57px;
    margin: 0;
}
</style>
